import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">20 X 20 / 上半場</h2>
          <h1 className="main_title">賴映如</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
                <div className="context">
                  <p>賴映如認為人類是生態系的一部份，卻對於生態卻非常不了解，她認為公共藝術可以作為引發人們對於生態環境好奇的媒介與方式，讓人造空間與環境景觀重新詮釋、創造新關係與新溝通。 Space──創客空間、自造空間，希望讓動手做、自學、共學、學新科技、學老東西的可能可以在這裡發生。</p>
                  <p>賴映如以一句充滿詩意的話，開始她的分享：「森林是海洋的戀人」，因為森林產生的所有養分都會回到海洋。人類所生活的陸地在海洋之間，而人類是生態循環體系的一部分，從這個角度去閱讀自然的訊息。</p>
                  <p>社宅基地有許多老樹，讓建築師很困擾。其實我們對於台灣熱帶雨林的老樹，非常不了解，如果公共藝術可以以藝術的角度去重新了解它的生態棲地，去改變環境來適應它，詮釋、理解他，真的不行，讓他回到森林裡，也可能一個行為藝術，與大眾做一個溝通、對話。</p>
                  <p>植物是光的子民。光看不到，植物不會說話，但他的影子充滿了故事。不同的植物在不同的季節。沒有一個地方和角度是一模一樣的，環境會說話，都在我們的生活當中。樹的光影打在不同的立面、材質上的表情，可不可以是公共藝術呢？。那建築立面就是公共藝術的載體。在這之上上演的植物光影的故事的變化，可以提醒我們關心我們的環境，引導人類對環境的好奇心。</p>
                  <p className="onset_right_1">土壤的豐富機制，和生物的滋長，或許可以透過藝術的翻譯，讓人理解、感動。透過展覽，知道我們的氣候和景觀真是我們的寶藏。賴映如相信，人們一開始對自然感到好奇，一定會深深地愛上。只要愛上，設計師就有機會為環境做出更多元開放的規劃。</p>
                  <p className="onset_right_1">自然界裡頭還有很多很有趣東西，例如自然很不自然，例如種子顏色的美麗不是為了美麗而是有生物學上的道理。再看植物園裡的荒廢溫室，裡面就是一個社會，而自然的知性知識，如果藝術和公眾對於環境的理解和保護行動結合在一起，何嘗不是社宅公共藝術可以考慮的良好方向呢？ </p>
                </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_1_5" className="next_page">
            <div className="next_title">彭永翔</div>
            <div className="next_owner">20 X 20 / 上半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
